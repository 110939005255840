<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <div class="mb-3 px-4 text-center">
        <b-img
          src="/logo.png"
          fluid
          alt="FINavigator Logo"
          class="logo mx-auto"
        />
      </div>
      <b-row class="justify-content-center row-eq-height">
        <b-col md="7" class="pr-1 pl-1">
          <b-card no-body>
            <b-card-body class="p-4">
              <h2>Verify User Account</h2>
              <p class="text-muted mb-4">
                For security purposes, users must verify their email address to
                maintain an active account.
              </p>
              <h6>A Verification Code has been sent to your email address.</h6>
              <b-input-group class="">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fa fa-key"></i
                  ></span>
                </div>
                <input
                  type="password"
                  class="form-control"
                  :class="{
                    'is-invalid': $v.otp.$error,
                    'is-valid': !$v.otp.$invalid
                  }"
                  placeholder="Enter Verification Code here"
                  v-model="otp"
                  @keyup.enter="verify"
                  @blur="$v.otp.$touch()"
                />
                <b-form-invalid-feedback
                  v-if="!$v.otp.required"
                  class="within-input mb-2"
                  >can't be blank</b-form-invalid-feedback
                >
              </b-input-group>
              <b-row class="mb-3 mt-2">
                <b-col cols="6" class="text-left">
                  <b-button
                    variant="link"
                    class="px-0 no-focus-highlt no-border"
                    @click="logInAsDifferentUser"
                    >Login as different user?</b-button
                  >
                </b-col>
                <b-col cols="6" class="text-right" v-if="inactiveEmail">
                  <b-button
                    variant="link"
                    class="px-0 no-focus-highlt no-border"
                    @click="resendVerificationCode"
                    >Resend Code
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="mb-4 text-center">
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    class="px-4 login-btn"
                    :disabled="$v.otp.$invalid || submitting"
                    @click="verify"
                  >
                    {{ submitText() }}</b-button
                  >
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'VerifyEmail',
  components: {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.authorizedUser ? vm.$router.push('/') : next()
    })
  },
  beforeCreate() {
    document.title = 'FI Navigator'
  },
  created() {
    this.setInactiveEmail()
    //this.resendCountDownTimer()
  },
  data() {
    return {
      otp: null,
      submitting: false
      //resendOTPTime: 30
    }
  },
  computed: {
    ...mapState('Authentication', {
      isActive: state => state.isActive,
      inactiveEmail: state => state.inactiveEmail
    }),
    ...mapGetters('Authentication', ['authorizedUser'])
    // disableResendOTP() {
    //   return this.resendOTPTime !== 0
    // }
  },
  methods: {
    ...mapActions('Authentication', ['verifyOTP', 'resendOTP', 'logOut']),
    ...mapMutations('Authentication', ['setUserInactiveEmail']),
    setInactiveEmail() {
      if (this.$route.query.token) {
        this.setUserInactiveEmail(atob(this.$route.query.token))
      }
    },
    verify: function() {
      this.submitting = true

      this.verifyOTP({ email: this.inactiveEmail, otp: this.otp.trim() }).then(
        () => {
          this.submitting = false
        }
      )
    },
    logInAsDifferentUser() {
      this.logOut({ silent: true })
    },
    resendVerificationCode() {
      this.resendOTP({ email: this.inactiveEmail })
      // .then(() => {
      //   this.resendOTPTime = 30
      //   this.resendCountDownTimer()
      // })
    },
    submitText: function() {
      return this.submitting ? 'Submitting...' : 'Submit Code'
    }
    // resendCountDownTimer() {
    //   setTimeout(
    //     function() {
    //       if (this.resendOTPTime > 0) {
    //         this.resendOTPTime -= 1
    //         this.resendCountDownTimer()
    //       }
    //     }.bind(this),
    //     1000
    //   )
    // }
  },
  validations: {
    otp: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-msg {
  margin-bottom: 0;
}

.login-btn {
  margin-top: 0.3rem;
}

.within-input {
  position: absolute;
  margin-top: 2.3rem;
}

.logo {
  max-height: 100px;
}
</style>
